import { faUtensils } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RestaurantSearch } from '../../components/RestaurantSearch/RestaurantSearch'
import styles from './HomepageMasthead.module.scss'
import Link from 'next/link'

const HomepageMasthead = () => {
  const { t } = useTranslation(['homepage'])
  return (
    <Container className={classNames('position-relative', styles.masthead)}>
      <div className={classNames('position-absolute', styles.videoOverlay)}></div>
      <video className="position-absolute" playsInline autoPlay muted loop>
        <source src="sizzly_dash.mp4" type="video/mp4" />
      </video>
      <Container
        className={classNames('h-100 text-white text-center position-relative', styles.mastheadTopLayerContainer)}
      >
        <Row className="h-100 align-items-center m-0 g-0">
          <Col sm={12}>
            <div className="pb-4">
              <h1 className="font-weight-bold text-uppercase m-2">
                <>{t('homepage:orderAndPayHeading')}</>
              </h1>
              <h4 className="m-2">
                <>{t('homepage:orderAndPaySubheading')}</>
              </h4>
            </div>
            {/*<div>{RestaurantSearch('restaurants', 'search')}</div>
            <Link href={'/restaurants'}>
              <a className={classNames('rounded-pill btn pt-2 text-uppercase', styles.allRestaurantButton)}>
                <FontAwesomeIcon icon={faUtensils} />
                <span className="ps-2">{t('homepage:allRestaurants')}</span>
              </a>
  </Link>*/}
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default HomepageMasthead
